<template>
  <div class="btn-menu" @click="gotoHome">
    <!-- <v-icon>mdi-menu</v-icon> -->
    <v-icon large class="btn-icon-back">mdi-arrow-left</v-icon>
  </div>
</template>

<script>
export default {
  methods: {
    gotoHome() {
      this.$router.push({ name: "Museum" });
    },
  },
};
</script>

<style lang="scss">
.theme--dark.v-icon.btn-icon-back{
color:$color--brown;
}</style>
