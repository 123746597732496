<template>
  <div class="btn-menu" @click="gotoMapMenu">
    <img
      class="map-icon-item"
      :src="require(`../assets/images/gallery1/icons/map-icon-menu.png`)"
      width="28"
      height="28"
      alt=""
    />
  </div>
</template>

<script>
export default {
  methods: {
    gotoMapMenu() {
      this.$router.push({ name: "Maps" });
    },
  },
};
</script>

<style></style>
