<template>
  <div id="exhibition-1" class="exhibition-container">
    <transition name="fade">
      <btnMapMenu v-if="isMapPages == 1" />
    </transition>
    <transition name="fade">
      <btnHome v-if="isMapPages != 1" />
    </transition>
    <div class="right-toolbar">
      <div class="map-icon-container">
        <transition name="fade">
          <img
            class="map-icon-item"
            v-show="currentMap == -1"
            :src="require(`../assets/images/gallery1/icons/map-icon-menu.png`)"
            width="28"
            height="28"
            alt=""
          />
        </transition>
        <transition-group name="fade" mode="out-in">
          <img
            class="map-icon-item"
            v-show="currentMap != -1 && currentMap == i - 1"
            v-for="i in 8"
            :key="`icon-${i}`"
            :src="
              require(`../assets/images/gallery1/icons/map-icon-${i - 1}.png`)
            "
            width="28"
            height="28"
            alt=""
            @click="openDialog"
          />
        </transition-group>
      </div>
      <div class="divider-vertical"></div>

      <div class="subtitle">
        <img
          src="@/assets/images/subtitle-1.svg"
          width="100%"
          height="auto"
          alt=""
        />
      </div>
    </div>
    <transition name="fade">
      <router-view />
    </transition>
    <v-dialog v-model="mapDialog" width="600">
      <v-card>
        <v-btn class="btn-close-dialog top-left" icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text v-if="currentMap >= 0" v-html="labels[currentMap].content">
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Howl, Howler } from "howler";
import { gsap } from "gsap";
import btnHome from "@/components/btnHome";
import btnMapMenu from "@/components/btnMapMenu";
export default {
  data() {
    return {
      myMusic: null,
      myLoop: null,
      labels: [
        {
          name: "map-0",
          content:
            "<p>「中亞五國」——哈薩克、烏茲別克、吉爾吉斯、塔吉克和土庫曼——是蘇聯解體後陸續宣佈獨立的民族國家。而她們之間的邊界，則是在蘇聯佔領時期在各種權力角力與利益考量下劃分出來的。例如，在此之前很漫長的一段時間裡，河中流域的歷史名城布哈拉和撒馬爾干（如今均在烏茲別克境內），波斯人和突厥人長期共存，大部份民眾也能掌握波斯語和突厥語。</p>",
        },
        {
          name: "map-1",
          content:
            "<p>當我們仰望上蒼，古人也必然曾凝視過同一片宇宙星空。仰觀宇宙之大，我們可有學會謙卑？中亞遊牧民族與自然共生，有著「天 - 地/人 - 靈」上中下三界的宇宙觀，敬畏天神，尊重自然界的神靈。中世紀伊斯蘭在中亞發展蓬勃，迎來伊斯蘭黃金時代，謙卑地追求科學的理性與精準，例如清真寺和經學院的建築圖飾呈現星體運行規律的和諧，以彰顯造物（真主）的偉大。</p>",
        },
        {
          name: "map-2",
          content:
            "<p>歐洲和亞洲北方是一片較為平坦的土地，被稱為「歐亞大陸」（Eurasia），它主要由草原、綠洲和沙漠組成，其中草原面積最大，包含有蒙古草原、哈薩克草原、黑海-裏海草原等。歐亞草原 (Eurasian Steppe) 是地球上最寬廣的草原地帶，也是草原文明的發祥地。</p>",
        },
        {
          name: "map-3",
          content:
            "<p>大自然的時間觀和現代人的時間觀不同。吉爾吉斯人、哈薩克人和維吾爾人敬仰的神聖天山，自有她孕育河流、再由河流滋潤湖泊與土地的時間。美麗富饒的費爾干納谷，自有她春華秋實的時間。然而人類的「活動」也會影響大自然的時間，例如蘇聯時期大面積開墾沙漠種棉花，大開運河渠道引流阿姆河與錫爾河來灌溉，造成下游的鹹海逐漸乾枯，生態環境遭嚴重破壞，鄰近居民生活環境也甚為惡化。如今位於烏茲別克境內的南鹹海幾乎消失。</p>",
        },
        {
          name: "map-4",
          content:
            "<p>河流是孕育人類文明的重要源頭。介於阿姆河（Amu Darya）和錫爾河（Syr Darya）之間的「河中流域」（Transoxania），源自天山支脈、流經吉爾吉斯的「楚河流域」，巴爾喀什湖以南、以伊犁河、塔拉斯和楚河為主要河流間的「七河流域」（突厥語中數字「七」代表多的意思），是中亞文明的搖籃，孕育了燦爛的遊牧文明和農業文明。</p>",
        },
        {
          name: "map-5",
          content:
            "<p>「河中流域」Transoxania是世界文明啟蒙地之一，由阿姆河（發源於帕米爾高原）和錫爾河（發源於天山）流域所滋潤。中世紀時是伊斯蘭哲學思想的發展重地，也是數學、幾何學、天文、地理和醫學等科學知識的發展中心。「Transoxania」源自阿拉伯文「過了阿姆河以外的地方」的意思，涵蓋了如今的烏茲別克和塔吉克，以及哈薩克和吉爾吉斯部分的地區。在蘇聯時期凸顯中亞民族差異以前，一直使用「Transoxania」這個名稱指代這片土地，強調的是地理和社會文化的連貫性。本地圖作品的原型來自 <span class='font-italic'>The Hundred Thousand Fools of God: Musical Travels in Central Asia (and Queens, New York)</span> (Theodore Levin, 1999) 一書第xiv頁的地圖。</p>",
        },
        {
          name: "map-6",
          content:
            "<p>撒馬爾干始於公元前七百多年，當時是波斯帝國行省索格底亞那的主要城市，其悠久的歷史足以媲美羅馬、巴比倫等古城。城裡的雷吉斯坦廣場（Registan Square)，上有三座恢宏的經學院，代表著中世紀中亞帝國的伊斯蘭建築之成就。經學院不僅傳授神學，還教授數學、天文學、哲學等知識，因此廣場也成為了當時的文教中心。中亞的古城，還有擁有2500年歷史的商賈與學術之城布哈拉，完整保存了十六世紀伊斯蘭建築文化的希瓦，自古為兵家必爭之地的奧什，蘇菲聖城突厥斯坦，等等。</p>",
        },
        {
          name: "map-7",
          content:
            "<p>國家級的博物館通常凸顯民族國家的歷史正當性，對內凝聚民族向心力、對外彰顯民族自豪。無論從建築設計還是策展考量，會運用上不少抽象的民族符號與元素。</p><p>讓我們團隊印象深刻的有兩個博物館。</p><p>蘇萊曼山博物館位於奧什聖山的山洞中，陳列著曾經在此傳播的祆教、薩滿教、佛教和伊斯蘭教等信仰，這個與聖山合為一體的博物館也同時保護著洞穴裡珍貴的古老岩畫；而博物館的設計則帶有蘇聯先鋒主義的色彩。</p><p>卡拉卡帕克國立美術館除了展出卡拉卡帕克歷史文物外，還是保存俄羅斯二十世紀美術創作的最重要地方。當年俄國藝術家被斯大林整肅迫害，多得俄羅斯藝術家薩維斯基四處奔走收集他們的作品，並帶到卡拉卡帕克的首都努庫斯保存起來。而移居到卡拉卡帕克的薩維斯基，在五十年代開始收集當地的傳統工藝，又鼓勵當地人將沙漠的遊牧生活和市集剪影融入創作中。某種程度上這個美術寶庫跨越了民族和國家的界限，並記錄了蘇聯時期俄羅斯藝術家的藝術信仰之實踐。</p>",
        },
      ],
      dimmed: true,
      sectionsName: [0, 1, 2, 3, 4],
    };
  },
  components: { btnHome, btnMapMenu },
  mounted() {
    this.$store.commit("updateVisited", 0);
    this.$store.commit("updateCurrentGallery", 0);

    this.myMusic = new Howl({
      src: ["../../music/gallery1/opening.mp3"],
      loop: false,
    });
    this.myMusic.once("load", () => {
      this.myMusic.stop();
      this.myMusic.play();
    });
    this.myMusic.on("end", () => {
      this.myLoop = new Howl({
        src: ["../../../music/gallery1/loop.mp3"],
        loop: true,
      });
      this.myLoop.once("load", () => {
        this.myLoop.stop();
        this.myLoop.play();
      });
    });

    document.querySelector("html,body").style.overflow = "auto";

  },
  destroyed() {
    if (this.myMusic?.playing()) {
      this.myMusic?.once("fade", () => {
        this.myMusic.unload();
      });
    }
    this.myMusic?.fade(this.myMusic.volume(), 0, 1000);
    if (this.myLoop?.playing()) {
      this.myLoop?.once("fade", () => {
        this.myLoop.unload();
      });
    }
    this.myLoop?.fade(this.myLoop.volume(), 0, 1000);
  },
  methods: {
    openDialog() {
      this.mapDialog = true;
    },
    closeDialog() {
      this.mapDialog = false;
    },
    svgLoaded() {
      // this.updateIcon();
    },
    updateIcon() {
      const tl = new gsap.timeline();
      tl.set("#map-icons > g", {
        opacity: 1,
      });
      tl.to(`#map-icons > g:nth-child(${this.currentMap + 2})`, {
        duration: 0.3,
        opacity: 1,
      });
    },
  },
  computed: {
    mapDialog: {
      get: function() {
        return this.$store.state.gallery1.mapDialog;
      },
      set: function(val) {
        this.$store.commit("updateMapDialog", val);
      },
    },
    currentMap() {
      return this.$store.state.gallery1.currentMap;
    },
    currentSection() {
      return this.$store.state.gallery1.currentSection;
    },
    showArrows() {
      return this.$store.state.gallery1.showArrows;
    },
    isMapPages() {
      return this.$route.path.indexOf("gallery-exploratorium/map/");
    },
    path() {
      return this.$route.path;
    },
    tracks() {
      return this.$store.state.audio.tracks;
    },
    currentTrack() {
      return this.$store.state.audio.currentTrack;
    },
  },
  // watch: {
  //   currentMap() {
  //     const vm = this;
  //     setTimeout(function() {
  //       vm.openDialog();
  //     }, 4500);
  //   },
  // },
};
</script>

<style lang="scss">
.divider-vertical {
  width: 1px;
  border-left: 1px solid $color--brown;
  height: 23px;
  position: absolute;
  transform: translate(-8px, 5px);
}
.map-icon-container {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50px, 3px);
  z-index: 9;
}
.map-icon-item {
  position: absolute;
  cursor: pointer;
}
.gallery-1-bg-container {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  background: url("../assets/images/gallery1/bg-pattern.png") center center
    no-repeat;
  background-size: contain;
  transform: translateY(-5%);
  // background-repeat: no-repeat;
}
.v-dialog {
  box-shadow: none;
}
.v-card {
  padding: 2rem;
}
.v-card__text {
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.8rem;
}
.theme--dark.v-card {
  background-color: rgba(0, 0, 0, 0.5) !important;
  // background:$color--black;
}
.v-overlay__scrim {
  // opacity:.5 !important;
  background-color: rgba(0, 0, 0) !important;
}
</style>
